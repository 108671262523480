import React from "react"
import { FormattedMessage } from "react-intl"

import { resetCurrentValue } from "./CookieConsentDialog"

const PlaceholderCookieConsentReset = () => {
  // see: https://github.com/Mastermindzh/react-cookie-consent#reset-the-cookies-value-in-your-own-code
  return (
    <p>
      <button className="button" onClick={resetCurrentValue}>
        <FormattedMessage id="general.cookie-consent.reset" />
      </button>
    </p>
  )
}

export default PlaceholderCookieConsentReset
