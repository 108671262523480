import React, { useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../layouts"

import { goToSlideByNumber, getCarouselPlugins } from "../utils/carousel-utils"
import { getShopifyContentfulMatcher } from "../utils/product-matcher"
import { smartReplacements } from "../utils/text-properties"
import useWindowWidth from "../utils/window-width"

import LocalizedLink from "../components/LocalizedLink"
import PlaceholderVideo from "../components/PlaceholderVideo"
import PlaceholderCookieConsentReset from "../components/PlaceholderCookieConsentReset"
import PlaceholderEmailAddress from "../components/PlaceholderEmailAddress"
import PlaceholderPostalAddress from "../components/PlaceholderPostalAddress"
import ProductBox from "../components/ProductBox"

const BlogPost = props => {
  const {
    pageContext: {
      locale,
      contentfulId,
      previousContentfulId,
      previousPath,
      nextContentfulId,
      nextPath,
    },
    data: {
      allContentfulBlogPost: { nodes: posts },
      allShopifyProductVariant: { nodes: relatedProductVariants },
      allContentfulProduct: { nodes: relatedProductVariantsContentful },
    },
    // pageContext: { relatedProductVariantIds, relatedProductVariantIdsBase64 },
  } = props

  // hooks
  const { formatMessage } = useIntl()

  /**
   * get localized page with smart replacements
   * @param {Array} pages
   * @param {String} locale
   * @param {String} id
   * @returns
   */
  const getLocalizedPage = (posts, locale, id = contentfulId) => {
    return posts
      .map(post => ({
        ...post,
        postFooterCtaLabel: smartReplacements(post.postFooterCtaLabel, locale),
        postTitle: smartReplacements(post.postTitle, locale)
      }))
      .find(post => post.contentful_id === id && post.node_locale === locale)
  }

  const localizedPost = getLocalizedPage(posts, locale)
  const localizedPreviousPost = getLocalizedPage(posts, locale, previousContentfulId)
  const localizedNextPost = getLocalizedPage(posts, locale, nextContentfulId)

  /**
   * get related product meta data if available
   * @param {Object} product
   * @param {String} locale
   * @returns
   */
  const getRelatedProductMetaData = (product, locale) => {
    return relatedProductVariantsContentful.find(c => {
      return (
        product.variants.some(
          v => getShopifyContentfulMatcher(v, c)
        ) && c.node_locale === locale
      )
    })
  }

  // carousel (multiple)
  const [currentSlides, setCurrentSlides] = useState([0, 0])

  const width = useWindowWidth()
  let numberOfSlidesShown = 5
  if (width <= 1216) {
    numberOfSlidesShown = 4
  }
  if (width <= 1024) {
    numberOfSlidesShown = 3
  }
  if (width <= 768) {
    numberOfSlidesShown = 2
  }
  if (width <= 480) {
    numberOfSlidesShown = 1
  }

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   *
   * The `scrollMarginTop` property ensures that the headline is in the field of view.
   * More details: https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
   */
  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <LocalizedLink to={`/${node.data.target.postSlug}`}>
            {node.content[0].value}
          </LocalizedLink>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.component === "Cookie Consent Reset") {
          return <PlaceholderCookieConsentReset />
        }
        if (node.data.target.component === "Email Address") {
          return <PlaceholderEmailAddress />
        }
        if (node.data.target.component === "Postal Address") {
          return <PlaceholderPostalAddress />
        }
        if (node.data.target.component === "Video") {
          const videoTitle = node.data.target.videoTitle || null
          const videoUrl = node.data.target.videoUrl || null
          return <PlaceholderVideo title={videoTitle} url={videoUrl} />
        }
        return <React.Fragment></React.Fragment>
      }
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  const pagerBackground =
    relatedProductVariants.length > 0
      ? "section"
      : "section has-background-paper"

  // sort related products according to order in Contentful
  const relatedProductVariantsSorted =
    [...relatedProductVariants].map((p, i) => {
      return relatedProductVariants.find(rp => {
        return getShopifyContentfulMatcher(rp, localizedPost.relatedProducts[i])
      })
    })

  return (
    <Layout title={localizedPost.postTitle} description={""}>
      <section className="section has-background-paper has-background-paper-top has-tree-paper has-tree-paper-middle">
        <div className="container">
          {/* title */}
          <div className="columns">
            <div className="column is-8">
              <h1 className="title my-6">{localizedPost.postTitle}</h1>
            </div>
          </div>

          {/* publication date & header image */}
          <div className="columns is-unspaced">
            <div className="column is-2 pl-3">
              <p className="has-text-grey"><FormattedDate value={localizedPost.publicationDate} year="numeric" month="long" day="2-digit" /></p>
            </div>
            <div className="column is-6">
              {localizedPost.postHeaderImage && (<Zoom>
                <GatsbyImage
                  objectFit="contain"
                  loading="eager"
                  alt={localizedPost.postTitle}
                  image={localizedPost.postHeaderImage.gatsbyImageData}
                /></Zoom>)}
            </div>
          </div>
        </div>
      </section>

      {/* header content */}
      <section className="section">
        <div className="container">
          <div className="columns is-unspaced">
            <div className="column is-6 is-offset-2 content">
              {localizedPost.postHeaderContent &&
                renderRichText(localizedPost.postHeaderContent, renderOptions)}
            </div>
          </div>
        </div>
      </section>

      {localizedPost.postSections && localizedPost.postSections.map((section, index) => {
        const sectionContent = (
          <React.Fragment>
            <h3 className="title">{section.sectionTitle}</h3>
            {section.sectionContent &&
              renderRichText(section.sectionContent, renderOptions)}
            {section.sectionCtaLabel && section.sectionCtaDestination && (
              <Link
                className="button is-special"
                to={section.sectionCtaDestination}
              >
                {section.sectionCtaLabel}
              </Link>
            )}
          </React.Fragment>
        )

        const sectionImages =
          section && section.sectionImages
            ? (
              <div className="image-stack-wrapper">
                <div className="image-stack">
                  <div className="image-stack-item-top">
                    <Zoom>
                      <GatsbyImage
                        objectFit="contain"
                        loading="lazy"
                        alt=""
                        image={section.sectionImages[0].gatsbyImageData}
                      />
                    </Zoom>
                  </div>
                  {section.sectionImages[1] && (<div className="image-stack-item-bottom">
                    <Zoom>
                      <GatsbyImage
                        objectFit="contain"
                        loading="lazy"
                        alt=""
                        image={section.sectionImages[1].gatsbyImageData}
                      />
                    </Zoom>
                  </div>)}
                </div>
              </div>
            )
            : <React.Fragment></React.Fragment>

        let sectionType = section.sectionType
        if (section.sectionType === "Auto") {
          sectionType = index % 2 === 0 ? "Left-aligned" : "Right-aligned"
        }

        const reversed =
          sectionType === "Left-aligned" ? "" : " is-reversed-mobile"
        const background =
          sectionType === "Left-aligned"
            ? "has-background-paper-right"
            : "has-background-paper-left"
        const hasTreeLeft =
          sectionType === "Left-aligned" ? " has-tree-paper-left" : ""

        return (
          <section
            key={index}
            className={`section ${background}${hasTreeLeft}`}
          >
            <div className="container">
              <div className={`columns is-unspaced${reversed}`}>
                {sectionType === "Left-aligned" && (
                  <React.Fragment>
                    <div className="column content is-4 is-offset-2 my-0 mb-4">
                      {sectionContent}
                    </div>
                    <div className="column is-5 is-offset-1 my-0 is-relative">
                      {sectionImages}
                    </div>
                  </React.Fragment>
                )}
                {sectionType === "Right-aligned" && (
                  <React.Fragment>
                    <div className="column is-5 my-0 is-relative">
                      {sectionImages}
                    </div>
                    <div className="column content is-4 is-offset-1 my-0 mb-4">
                      {sectionContent}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
        )
      })}

      {/* footer content */}
      <section className="section my-6">
        <div className="container">
          <div className="columns is-unspaced">
            <div className="column is-6 is-offset-2 content">
              {localizedPost.postFooterContent &&
                renderRichText(localizedPost.postFooterContent, renderOptions)}
              {localizedPost.postFooterCtaLabel && localizedPost.postFooterCtaDestination && (
                <Link
                  className="button is-special"
                  to={localizedPost.postFooterCtaDestination}
                >
                  {localizedPost.postFooterCtaLabel}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Related Products */}
      {relatedProductVariantsSorted.length > 0 && (
        <section className="section has-background-paper">
          <div className="container">
            <h2 className="subtitle">
              <FormattedMessage id={"general.post.used-products"} />
            </h2>
            {typeof window !== "undefined" && Carousel && <Carousel
              plugins={getCarouselPlugins(
                currentSlides,
                setCurrentSlides,
                0,
                relatedProductVariantsSorted.length,
                numberOfSlidesShown,
                formatMessage({ id: "general.previous" }),
                formatMessage({ id: "general.next" })
              )}
              value={currentSlides[0]}
              onChange={index =>
                goToSlideByNumber(
                  currentSlides,
                  setCurrentSlides,
                  0,
                  relatedProductVariantsSorted.length,
                  numberOfSlidesShown,
                  index
                )
              }
            >
              {relatedProductVariantsSorted.map(relatedProductVariant => (
                <ProductBox
                  area={relatedProductVariant.product.id}
                  key={relatedProductVariant.product.id}
                  product={{
                    ...relatedProductVariant.product,
                    handle:
                      getRelatedProductMetaData(
                        relatedProductVariant.product,
                        locale
                      )?.productSlug || relatedProductVariant.product.handle,
                    title:
                      getRelatedProductMetaData(
                        relatedProductVariant.product,
                        locale
                      )?.productTitle || relatedProductVariant.product.title,
                  }}
                  locale={locale}
                  onPaper={true}
                />
              ))}
            </Carousel>}
          </div>
        </section>
      )}

      {/* Pager */}
      <section className={pagerBackground}>
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-one-third-tablet is-half-mobile">
              {nextContentfulId !== null && (
                <LocalizedLink to={nextPath}>
                  <div className="card">
                    {localizedNextPost.postHeaderImage && (<div className="card-image">
                      <GatsbyImage
                        objectFit="contain"
                        loading="eager"
                        alt={localizedNextPost.postTitle}
                        image={localizedNextPost.postHeaderImage.gatsbyImageData}
                      />
                    </div>)}
                    <div className="card-content">
                      <div className="columns is-mobile">
                        <div className="column is-narrow">⟵</div>
                        <div className="column">{localizedNextPost.postTitle}</div>
                      </div>
                    </div>
                  </div>
                </LocalizedLink>
              )}
            </div>
            <div className="column is-one-third-tablet is-hidden-mobile"></div>
            <div className="column is-one-third-tablet is-half-mobile">
              {previousContentfulId !== null && (
                <LocalizedLink to={previousPath}>
                  <div className="card">
                    {localizedPreviousPost.postHeaderImage && (<div className="card-image">
                      <GatsbyImage
                        objectFit="contain"
                        loading="eager"
                        alt={localizedPreviousPost.postTitle}
                        image={localizedPreviousPost.postHeaderImage.gatsbyImageData}
                      />
                    </div>)}
                    <div className="card-content">
                      <div className="columns is-mobile">
                        <div className="column">{localizedPreviousPost.postTitle}</div>
                        <div className="column is-narrow">⟶</div>
                      </div>
                    </div>
                  </div>
                </LocalizedLink>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <pre>{JSON.stringify(localizedPost.postSections, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariants, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantsContentful, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantIdsBase64, null, 2)}</pre> */}
    </Layout>
  )
}

export default BlogPost

export const BlogPostQuery = graphql`
  query (
    $contentfulId: String!
    $previousContentfulId: String
    $nextContentfulId: String
    $relatedProductVariantIds: [String!]
    $relatedProductVariantIdsBase64: [String!]
  ) {
    allContentfulBlogPost(filter: { contentful_id: { in: [$contentfulId, $previousContentfulId, $nextContentfulId] } }) {
      nodes {
        contentful_id
        node_locale
        postTitle
        publicationDate
        postHeaderImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1280
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        postHeaderContent {
          raw
          references {
            ... on ContentfulPlaceholder {
              contentful_id
              component
              videoTitle
              videoUrl
            }
          }
        }
        postSections {
          contentful_id
          node_locale
          sectionTitle
          sectionType
          sectionCtaLabel
          sectionCtaDestination
          sectionContent {
            raw
            references {
              ... on ContentfulPlaceholder {
                contentful_id
                component
                videoTitle
                videoUrl
              }
            }
          }
          sectionImages {
            id
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 80
              height: 600
              breakpoints: [480, 768, 1024, 1216, 1408]
            )
          }
        }
        postFooterContent {
          raw
          references {
            ... on ContentfulPlaceholder {
              contentful_id
              component
              videoTitle
              videoUrl
            }
          }
        }
        postFooterCtaLabel
        postFooterCtaDestination
        relatedProducts {
          shopifyProductVariantId
        }
      }
    }
    allShopifyProductVariant(
      filter: { storefrontId: { in: $relatedProductVariantIds } }
    ) {
      nodes {
        storefrontId
        product {
          collections {
            id
            handle
            title
          }
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          hasOutOfStockVariants
          id
          images {
            altText
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              aspectRatio: 0.75
              breakpoints: [100, 200, 300, 400]
            )
          }
          isGiftCard
          options {
            id
            name
            values
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          storefrontId
          title
          totalVariants
          variants {
            availableForSale
            compareAtPrice
            id
            price
            storefrontId
            title
          }
        }
      }
    }
    allContentfulProduct(
      filter: { shopifyProductVariantId: { in: $relatedProductVariantIdsBase64 } }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
  }
`
