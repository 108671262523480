import React from "react"

const PlaceholderPostalAddress = () => {
  return (
    <p>
      woodist
      <br />
      Marén Schaake
      <br />
      Bötzowstraße 8<br />
      10407 Berlin, Germany
      <br />
    </p>
  )
}

export default PlaceholderPostalAddress
