import React from "react"

const PlaceholderVideo = ({ title, url }) => {
  let code = null
  if (url !== null) {
    const videoUrlSearchParams = (new URL(url)).searchParams
    if (videoUrlSearchParams.has("v")) {
      code = videoUrlSearchParams.get("v")
    }
  }

  if (code === null) {
    return <React.Fragment></React.Fragment>
  }

  return (
    <figure className="image is-16by9 mx-0">
      <iframe
        className="has-ratio"
        width="640"
        height="360"
        src={`https://www.youtube.com/embed/${code}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}></iframe>
    </figure>
  )
}

export default PlaceholderVideo
