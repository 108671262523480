import React from "react"
import {
  arrowsPlugin,
  slidesToScrollPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel"
import { IoArrowBack, IoArrowForward } from "react-icons/io5"

export const goToSlideByNumber = (
  currentSlides,
  setCurrentSlides,
  index,
  numberOfSlides,
  numberOfSlidesShown,
  number
) => {
  if (!isNaN(number)) {
    number = Math.max(0, number)
    number = Math.min(numberOfSlides - numberOfSlidesShown, number)
    const tempCurrentSlides = [...currentSlides]
    tempCurrentSlides[index] = number
    setCurrentSlides(tempCurrentSlides)
  }
}

export const goToSlideByStep = (
  currentSlides,
  setCurrentSlides,
  index,
  numberOfSlides,
  numberOfSlidesShown,
  step
) => {
  if (step < 0 && currentSlides[index] > 0) {
    const tempCurrentSlides = [...currentSlides]
    tempCurrentSlides[index]--
    setCurrentSlides(tempCurrentSlides)
  }
  if (step > 0 && currentSlides[index] < numberOfSlides - numberOfSlidesShown) {
    const tempCurrentSlides = [...currentSlides]
    tempCurrentSlides[index]++
    setCurrentSlides(tempCurrentSlides)
  }
}

export const goToPreviousSlide = (
  currentSlides,
  setCurrentSlides,
  index,
  numberOfSlides,
  numberOfSlidesShown
) =>
  goToSlideByStep(
    currentSlides,
    setCurrentSlides,
    index,
    numberOfSlides,
    numberOfSlidesShown,
    -1
  )

export const goToNextSlide = (
  currentSlides,
  setCurrentSlides,
  index,
  numberOfSlides,
  numberOfSlidesShown
) =>
  goToSlideByStep(
    currentSlides,
    setCurrentSlides,
    index,
    numberOfSlides,
    numberOfSlidesShown,
    1
  )

export const getCarouselPlugins = (
  currentSlides,
  setCurrentSlides,
  index,
  numberOfSlides,
  numberOfSlidesShown,
  previousLabel,
  nextLabel
) => {
  return [
    "fastSwipe",
    {
      resolve: arrowsPlugin,
      options: {
        arrowLeft: (
          <button
            className="button"
            // aria-label={formatMessage({ id: "general.previous" })}
            aria-label={previousLabel}
            disabled={currentSlides[index] === 0}
            onClick={() =>
              goToPreviousSlide(
                currentSlides,
                setCurrentSlides,
                index,
                numberOfSlides,
                numberOfSlidesShown
              )
            }
          >
            <span className="icon is-small">
              <IoArrowBack />
            </span>
          </button>
        ),
        arrowRight: (
          <button
            className="button"
            // aria-label={formatMessage({ id: "general.next" })}
            aria-label={nextLabel}
            disabled={
              currentSlides[index] === numberOfSlides - numberOfSlidesShown
            }
            onClick={() =>
              goToNextSlide(
                currentSlides,
                setCurrentSlides,
                index,
                numberOfSlides,
                numberOfSlidesShown
              )
            }
          >
            <span className="icon is-small">
              <IoArrowForward />
            </span>
          </button>
        ),
        addArrowClickHandler: false,
      },
    },
    {
      resolve: slidesToScrollPlugin,
      options: { numberOfSlides: 1 },
    },
    {
      resolve: slidesToShowPlugin,
      options: { numberOfSlides: numberOfSlidesShown },
    },
  ]
}
