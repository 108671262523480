import React from "react"
import Obfuscate from "react-obfuscate"

const PlaceholderEmailAddress = () => {
  return (
    <p>
      <Obfuscate email="hallo@woodist.de" />
    </p>
  )
}

export default PlaceholderEmailAddress
